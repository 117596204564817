@media only screen and (device-width:768px) and (device-height:1024px) and (-webkit-min-device-pixel-ratio:1) {
	// ========================== LIVE B2C ======================= //
	.header__profpic {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header__title {
		flex: 4;
		color: $secondary;
		font-size: 1.5em;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header__mobile {
		flex: 0 64px;
		background-color: transparent;
		display: flex;
		height: 60px;
	}

	.header__desktop {
		display: none;
	}

	.main__sidebar {
		display: none;
	}

	.mobile__menu {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dashboard,
	.profile {
		width: 95%;

		&__menu {
			justify-content: space-between;
			flex-wrap: wrap;

			div {
				height: 120px;
				margin-bottom: 10px;

				img {
					width: 70px;
				}

			}

		}

		&__menutab {
			.boxsessions {
				margin-top: 30px;

				&__today {
					.boxinfo {
						left: 0px !important;
						width: 95% !important;
						margin-left: 5px;
					}

				}

			}

		}

		.dashboard__resultbook {
			.profilecoach {
				width: 25%;
			}

		}

	}

	// ==============================++++++++ BOOK A COACH +++++++========================//

	.dashboard__bookacoach {
		&__searchname {
			input {
				width: 70% !important;
			}

		}

		.date-picker .ui-datepicker-header {
			font-size: 0.9em;
		}

		.date-picker .input .result {
			font-size: 0.8em !important;
			letter-spacing: -1.5px !important;
		}

		.date-picker .input button {
				width: 10px !important;
			

		}

	}

	.dashboard__resultbook {
		padding: 0 30px;
		justify-content: space-between!important;
		.boxprofilecoach {
			width: 270px !important;

			.profilecoach__timebook {
				.accordion-item {
					width: 100% !important;
				}

				/* Thumb */

				/* Panel */
				.accordion-panel {
					width: 100% !important;
				}

			}

		}

	}

}

// ====================================== LIVE B2C ================================================ //



// ============================= FOR IPHONE 5 and 5s =============================== //

@media only screen and (min-device-width:320px) and (max-device-width:568px) {
	.header__profpic {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header__title {
		flex: 4;
		color: $secondary;
		font-size: 1.5em;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header__mobile {
		flex: 0 64px;
		background-color: transparent;
		display: flex;
		height: 50px;
	}

	.header__desktop {
		display: none;
	}

	.main__content {
		display: block;
	}

	.main__sidebar {
		display: none;
	}

	.mobile__menu {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dashboard {
		width: 100%;

		&__notif {
			width: 100% !important;
		}

		&__menu {
			justify-content: space-between;
			flex-wrap: wrap;

			div {
				width: 47% !important;
				height: 120px;
				margin-bottom: 10px;

				img {
					width: 50px;
				}

			}

		}

		&__menutab {
			.boxsessions {
				margin-top: 30px;

				&__today {
					.todaysessions {
						width: 100% !important;
					}

					.boxinfo {
						left: 0 !important;
						width: 95% !important;
						margin-left: 5px;
					}

				}

			}

		}

	}

	// ==============================++++++++ Study Dashboard +++++++========================//
	.study__dashboard__top, .study__dashboard__bottom {
		display: block;
	}
	.progress__step, .progress__point__top {
	    width: 100%; 
	}
	.study__progress__graph {
		width: 100%!important;
	}
	.donut__progress {
		padding: 10px;
	}
	.header__profpic {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header__title {
		flex: 4;
		color: $secondary;
		font-size: 1.5em;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header__mobile {
		flex: 0 64px;
		background-color: transparent;
		display: flex;
		height: 50px;
	}
	.header__desktop {
		display: none;
	}
	.main__sidebar {
		display: none;
	}
	.mobile__menu {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	// ==============================++++++++ Study Dashboard +++++++========================//

	.help__content {
		width: 100%!important;
	}
	.help__accordion {
		display: block!important;
	}
	.accordion {
		width: 100%;
	}

	// ==============================++++++++ PROFILE +++++++========================//

	.profile {
		width: 100%!important;
    	max-height: 100%!important;

		&__info {
			width: 100% !important;
			order: 1;
		}

		&__password {
			width: 100% !important;
			order: 3;
		}

		&__additional {
			width: 100% !important;
			order: 2;
		}

		&__dynedpro {
			order: 4;
			width: 100% !important;
			margin-bottom: 20px;
		}

	}

	// ==============================++++++++  Booking Coach for Mobile +++++++========================//

	.dashboard__bookacoach {
		display: none;
	}

	.dashboard__bookacoachmobile {
		margin-top: 10px;
		display: flex !important;
		flex-direction: column;
		width: 100%;

		.boxselect {
			display: flex !important;
			border: 2px solid #606983;
			height: 70px;
			background-color: rgba(56, 70, 112, .98);

			select {
				width: 60%;
				height: 35px;
				font-size: 1em;
				color: $white;
				margin: auto;
				letter-spacing: 1px;
				text-indent: 50px;
			}

		}

		#alt1 {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 2px solid #606983;
			background-color: rgba(56, 70, 112, .98);

			h1 {
				margin-top: 5px;
				font-size: 1.2em;
				margin-bottom: 10px;
			}

			input {
				height: 30px;
				width: 85%;
			}

			button {
				margin-bottom: 20px;
				min-width: 100px;
				border-radius: 3px;
			}

		}

		#alt2 {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 2px solid #606983;
			background-color: rgba(56, 70, 112, .98);
			margin-bottom: 20px;

			date-picker {
				margin: 200px auto;
			}

			h1 {
				margin-top: 5px;
				font-size: 1.2em;
				margin-bottom: 10px;
				order: 1;
			}

			.btnsearch {
				order: 3;
				margin-bottom: 20px;

				button {
					min-width: 100px;
					border-radius: 3px;
				}

			}

			.date-picker {
				order: 2;
				width: 260px;
				height: auto;
				max-height: 30px;
				background-color: rgba(56, 70, 112, .98);
				position: relative;
				overflow: hidden;
				-webkit-transition: all 0.3s 0s ease-in-out;
				transition: all 0.3s 0s ease-in-out;
				border-radius: 3px;
			}

			.date-picker .input {
				width: 100%;
				height: 30px;
				font-size: 0;
				cursor: pointer;
				border-radius: 3px;
				background-color: white;
			}

			.date-picker .input .result,
			.date-picker .input button {
				display: inline-block;
				vertical-align: top;
			}

			.date-picker .input .result {
				width: calc(100% - 46px);
				height: 30px;
				line-height: 30px;
				font-size: 13px;
				padding: 0 10px;
				color: grey;
				box-sizing: border-box;

				span {
					color: black;
					font-weight: bold;
				}

			}

			.date-picker .input button {
				width: 30px;
				height: 30px;
				background-color: #fff;
				color: white;
				line-height: 30px;
				border: 0;
				font-weight: bold;
				font-size: 16px;
				padding: 0;
				position: relative;
				left: 10px;
				cursor: pointer;

				.fa-angle-down {
					color: gray;
				}

			}

			.date-picker .input button:hover {
				background-color: #fff;
			}

			.date-picker .input button:focus {
				outline: none;
			}

			.date-picker .calendar {
				position: relative;
				width: 100%;
				background: #fff;
				border-radius: 3px;
				overflow: hidden;
				margin-top: 15px;
			}

			.date-picker.open {
				max-height: 400px;
			}

			.date-picker .ui-datepicker-inline {
				position: relative;
				width: 100%;
			}

			.date-picker .ui-datepicker-header {
				height: 100%;
				line-height: 50px;
				background: #8392A7;
				color: #fff;
				margin-bottom: 10px;
			}

			.date-picker .ui-datepicker-prev,
			.date-picker .ui-datepicker-next {
				width: 20px;
				height: 20px;
				text-indent: 9999px;
				border: 2px solid #fff;
				border-radius: 100%;
				cursor: pointer;
				overflow: hidden;
				margin-top: 12px;
			}

			.date-picker .ui-datepicker-prev {
				float: left;
				margin-left: 12px;
			}

			.date-picker .ui-datepicker-prev:after {
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				margin: -43px 0px 0px 8px;
			}

			.date-picker .ui-datepicker-next {
				float: right;
				margin-right: 12px;
			}

			.date-picker .ui-datepicker-next:after {
				-webkit-transform: rotate(-135deg);
				transform: rotate(-135deg);
				margin: -43px 0px 0px 6px;
			}

			.date-picker .ui-datepicker-prev:after,
			.date-picker .ui-datepicker-next:after {
				content: "";
				position: absolute;
				display: block;
				width: 4px;
				height: 4px;
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}

			.date-picker .ui-datepicker-prev:hover,
			.date-picker .ui-datepicker-next:hover,
			.date-picker .ui-datepicker-prev:hover:after,
			.date-picker .ui-datepicker-next:hover:after {
				border-color: #68768A;
			}

			.date-picker .ui-datepicker-title {
				text-align: center;

				span {
				}

			}

			.date-picker .ui-datepicker-calendar {
				width: 100%;
				text-align: center;
			}

			.date-picker .ui-datepicker-calendar thead tr th span {
				display: block;
				width: 100%;
				color: #37456f;
				margin-bottom: 5px;
				font-size: 15px;
				font-weight: bold;
			}

			.date-picker .ui-state-default {
				display: block;
				text-decoration: none;
				color: #b5b5b5;
				line-height: 34px;
				font-size: 14px;
			}

			.date-picker .ui-state-default:hover {
				background: #49c5fe;
			}

			.date-picker .ui-state-highlight {
				color: #344269;
			}

			.date-picker .ui-state-active {
				color: #344269;
				background-color: rgba(73, 197, 254, 0.49);
				font-weight: 600;
			}

			.date-picker .ui-datepicker-unselectable .ui-state-default {
				color: #344269;
				border: 2px solid transparent;
			}

			.date-picker.open .input button {
				background: #fff;
			}

		}

		#alt3,
		#alt4 {
			margin-bottom: 20px;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 2px solid #606983;
			background-color: rgba(56, 70, 112, .98);

			h1 {
				margin-top: 5px;
				font-size: 1.2em;
				margin-bottom: 10px;
				order: 1;
			}

			.btnsearch {
				order: 3;
				margin-bottom: 20px;
				margin-top: 10px;

				button {
					min-width: 100px;
					border-radius: 3px;
				}

			}

			select {
				margin-top: 20px;
				width: 80%;
				order: 2;
				height: 40px;
				font-size: 1em;
				border-radius: 3px;
			}

			.option {
				color: red;
			}

		}

	}

	.dashboard__menubookingcoachresult {
		margin-top: 5px !important;

		div {
			width: 47% !important;
			height: 50px;
			margin-top: 10px;
		}

	}

	.dashboard__resultbook {
		width: 100% !important;

		.boxprofilecoach {
			width: 100% !important;
    		margin: 20px 0 !important;

			.profilecoach__timebook {
				.accordion-item {
					width: 100% !important;
				}

				/* Thumb */

				/* Panel */
				.accordion-panel {
					width: 100% !important;
				}

			}

		}

	}

	.dashboard__bxsummary {
		&__bookingsummary {
			width: 100% !important;

			h3 {
				margin-left: 0px !important;
				text-align: center;
			}

			div {
				margin-left: 0px !important;
				margin-right: 0px !important;
				height: 65px !important;

				&:nth-child(7) {
					border: none;
				}

				label,
				span {
					margin-left: 20px !important;
					margin-right: 20px !important;
				}

			}

			.bxbutton {
				width: 100% !important;
				margin-top: 20px !important;
			}

		}

	}

	.dashboard__bxtokenstab {
		.bxtabtokens {
			.bxrequest {
				&__requesttokens {
					width: 100% !important;
				}

			}

			.bxhistory {
				&__boxnotifstatus {
					i {
						font-size: 0.7em !important;
					}

					span {
						font-size: 0.9em !important;
					}

				}

				&__boxstatus {
					height: 140px !important;

					.daterefund {
						i {
							font-size: 0.7em !important;
						}

						span {
							font-size: 0.9em !important;
						}

					}

					.status {
						margin-top: 30px !important;
					}

				}

				&__boxsession {
					i {
						font-size: 0.7em !important;
						color: #a3aabe;
					}

					span {
						font-size: 0.9em !important;
					}

				}

			}

		}

	}

}