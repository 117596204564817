html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

body {
	background: url('../img/bgsStarLagrge.png');
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.wrapper {
	width: 100%;
	min-height: 100%;
	position: relative;
	height: 100%;
}

/* ============================ Global Settings ========================================== */
a:link {
	text-decoration: none;
	color: white;
}

a:visited {
	text-decoration: none;
	color: white;
}

a:hover {
	text-decoration: none;
	color: white;
}

.tab--content {
	display: none;
}

.tab--content.current {
	display: inherit;
}

// ========================= LAYOUT LIVE B2C =========================//

	footer {
		background-color: rgba(63,78,123,.7);
	}
	footer span {
		padding: 15px 0;
		font-size: 0.7em;
		font-weight: 300;
	}	
	.header__mobile {
		display: none;
	}
	.header__logo {
		flex: 4;
		margin-left: 50px; 
	}
	.hide {
		display: none;
	}
	.main__sidebar {
		flex: 1;
		background-color: rgba(63,78,123,.7);
	}
	.main__sidebar ul li {
		padding: 10px 0 10px 40px;
		font-size: 1em;
		font-weight: 300;
		cursor: pointer;
		transition: all 0.25s ease-in-out;
		display: flex;
	    align-items:center;
		
		svg{
    		position: relative;
    		right:20px;
    		// border:1px solid red;
    	}
    
    	span{
    		margin-top: -5px;
    		margin-left: -10px;
    	}
	}
	.main__sidebar ul li:hover {
		background-color: #303e62;
	}
	.main__content {
		flex: 4;
		display: flex;
    	align-items: center;
    	flex-direction: column;
    	width: 100%;
		padding: 10px;
		// background-image: url(../img/bgStarLarge.png);
	}
	.main {
		width: 100%;
		min-height: 100%;
	}
	.header__profile {
		flex: 1;
	}
	.dashboard, .profile {
    	width: 80%;
    
    	&__menu {
    		margin-top: 20px;
    	}
    
    	&__menutab {
    		margin-top: 20px;
    	}
    
    }
	.profile__name {
		margin: auto 20px auto 0
	}
	.profile__name h4 {
		font-size: 1em;
		font-weight: 300;
	}
	.profile__name h5 {
		line-height: 2;
		font-weight: 300;
		font-size: 0.7em;
		text-align: right;
	}
	.pic__circle--small {
		list-style: none;
	  	border-radius: 50%;
	  	width: 35px;
	  	height: 35px;
	  	position: relative;
	}
	.pic__circle--small img {
		border-radius: 50%;
		width: 35px;
		height: 35px;
	}

	/****** mobile menu ******/
	/* burger menu button */
	.burger__menu {
	  	cursor: pointer;
	}
	.menu__bar {
	  	width: 25px;
	  	height: 3px;
	  	margin: 3px 0;
	  	background-color: #fafafa;
	  	-webkit-transition: transform 200ms ease-in-out, opacity 200ms;
	  	transition: transform 200ms ease-in-out, opacity 200ms;
	}
	.menu__bar.clicked:nth-child(2) {
	  	opacity: 0;
	  	transform: translate(50px);
	}
	.menu__bar.clicked:nth-child(1) {
		background-color: #49C5FE;
	  	transform: rotate(45deg) translate(0px, -2px);
	  	position: absolute;
		z-index: 999;
	}
	.menu__bar.clicked:nth-child(3) {
		background-color: #49C5FE;
	  	transform: rotate(-45deg) translate(8px, -7px);
	  	position: absolute;
		z-index: 999;
	}
	/* mobile menu block */
	.mobile__nav {
	  	background-color: rgba(255, 255, 255, 0.9);
	  	position: absolute;
	  	left: 0;
	  	top: 0;
	  	z-index: 100;
	  	height: 100%;
	  	width: 100%;
	  	display: table;
	  	text-align: center;
	  	opacity: 0;
	  	-webkit-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	  	transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	  	-webkit-transform: scale(0);
	          	transform: scale(0);
	}
	.mobile__nav.act {
	  	opacity: 1;
	  	-webkit-transform: scale(1);
	          	transform: scale(1);
	}
	.mobile__nav.act ul li {
	  	opacity: 1;
	  	-webkit-transform: translateX(0);
	          	transform: translateX(0);
	}
	.mobile__nav ul {
	  	display: table-cell;
	  	vertical-align: middle;
	}
	.mobile__nav li {
	  	padding: 8px 0;
	  	-webkit-transition: all 400ms 510ms;
	  	transition: all 400ms 510ms;
	  	opacity: 0;
	}
	.mobile__nav li:nth-child(odd) {
	  	-webkit-transform: translateX(30%);
	          	transform: translateX(30%);
	}
	.mobile__nav li:nth-child(even) {
	  	-webkit-transform: translateX(-30%);
	          	transform: translateX(-30%);
	}
	.mobile__nav a {
	  	color: #49C5FE;
	  	display: inline-block;
	  	font-size: 1.2em;
	}
	.mobile__nav a.su_btn {
	  	color: #fff;
	}
	/****** end mobile menu ******/

// ========= end header sidebar footer =========//