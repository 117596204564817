.profile {
	display: flex;
	max-height: 779px;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;

	label {
		color: #49C5FE;
		margin-bottom: 5px;
		font-size: 0.9em;
	}

	span {
		font-size: 0.8em;
	}

	input {
		font-size: 1em;
		background: none;
		height: 25px;
		border: none;
		color: #fff;
		letter-spacing: 1px;
		outline: none;
	}

	&__info {
		border: 1px solid #606983;
		width: 45%;
		height: 346px;
		margin-top: 48px;
		border-radius: 3px;
		background-color: rgba(56, 70, 112, 0.98);

		&__picture {
			border: 1px solid #606983;
			height: 70px;
			width: 70px;
			border-radius: 100%;
			overflow: hidden;
			margin: auto;
			position: relative;
			bottom: 40px;

			img {
				width: 100%;
			}

		}

		&__name {
			font-size: 1.3em;
			font-weight: bold;
			text-align: center;
			margin-top: -25px;
			letter-spacing: 1px;
		}

		div:nth-child(3) {
			margin-top: 20px;
		}

		div:nth-child(6) {
			border: none;
		}

		&__birth,
		&__email,
		&__language,
		&__gender {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #606983;
			padding-left: 10px;
			height: 60px;
		}

	}

	&__password {
		margin-top: 20px;
		border: none;
		width: 45%;


		&__title {
			font-size: 1em;
			font-weight: bold;
			text-align: center;
		}

		div:nth-child(2) {
			margin-top: 10px;
			border-top: 1px solid #606983;
			border-radius: 3px;
		}

		div:nth-child(5) {
			border-radius: 2px;
		}

		&__current,
		&__new,
		&__confirm {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 60px;
			border-bottom: 1px solid #606983;
			border-left: 1px solid #606983;
			border-right: 1px solid #606983;
			padding-left: 10px;
			background-color: rgba(56, 70, 112, 0.98);

			input {
				padding-left: 10px;
				padding-right: 20px;
				margin-top: 5px;
			}

			label {
				margin-bottom: -5px;
			}

		}

		&__button {
			border-bottom: 1px solid #606983;
			border-left: 1px solid #606983;
			border-right: 1px solid #606983;
			height: 70px;
			display: flex;
			align-items: center;
			background-color: rgba(56, 70, 112, 0.98);

			button {
				min-width: 130px;
				margin-top: 0px;
				outline: none;
			}

		}

	}

	&__additional {
		border: none;
		width: 45%;
		margin-top: 14px;

		&__title {
			font-size: 1em;
			font-weight: bold;
			text-align: center;
			border: none;
		}

		div:nth-child(2) {
			margin-top: 10px;
			border-top: 1px solid #606983;
			border-radius: 2px;
		}

		div:nth-child(10) {
			border-radius: 2px;
		}

		&__token,
		&__skype,
		&__city,
		&__state,
		&__address,
		&__goal,
		&__like,
		&__dislike,
		&__timezone {
			display: flex;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 60px;
			border-bottom: 1px solid #606983;
			border-left: 1px solid #606983;
			border-right: 1px solid #606983;
			padding-left: 10px;
			background-color: rgba(56, 70, 112, 0.98);
		}

	}

	&__dynedpro {
		border: none;
		width: 45%;
		margin-top: 20px;

		&__title {
			font-size: 1em;
			font-weight: bold;
			text-align: center;
			border: none;
		}

		div:nth-child(2) {
			margin-top: 10px;
			border-top: 1px solid #606983;
			border-radius: 2px;
		}

		div:nth-child(3) {
			border-radius: 2px;
		}

		&__id,
		&__server {
			display: flex;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 60px;
			border-bottom: 1px solid #606983;
			border-left: 1px solid #606983;
			border-right: 1px solid #606983;
			padding-left: 10px;
				background-color: rgba(56, 70, 112, 0.98);

			label {
				margin-bottom: 10px;
			}

		}

	}

}