.activetitle {
	font-weight: bold;
	background-color: rgba(39, 52, 84, 0.36) !important;
	color: #49C5FE;

	span {
		color: #49C5FE;
	}

}

.notactive {
	color: #606983;
	font-weight: bold;
}

.dashboard__bookacoachmobile {
	display: none;
}

.dashboard__bookacoach {
	margin-top: 20px;
	border: 2px solid #606983;
	min-height: 130px;
	transition: all 0.3s 0s ease-in-out;

	&__searchname {
		margin-top: 20px;
		margin-bottom: 15px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-around;

		label {
			color: #49C5FE;
			font-size: 1em;
			font-weight: bold;
			letter-spacing: 0.5px;
		}

		input {
			text-align: center;
			font-size: 0.8em;
			height: 26px;
			border-radius: 4px;
			width: 75%;
			outline: none;
			border: none;
		}

	}

	&__search {
		@extend .flex--spacearound;

		.bydate {
			width: 27%;

			date-picker {
				margin: 200px auto;
			}

			.date-picker {
				height: auto;
				max-height: 30px;
				background-color: rgb(63, 78, 123);
				position: relative;
				overflow: hidden;
				-webkit-transition: all 0.3s 0s ease-in-out;
				transition: all 0.3s 0s ease-in-out;
				border-radius: 3px;
			}

			.date-picker .input {
				width: 100%;
				height: 30px;
				font-size: 0.9em;
				cursor: pointer;
				border-radius: 3px;
				background-color: white;
			}

			.date-picker .input .result,
			.date-picker .input button {
				display: inline-block;
				vertical-align: top;
			}

			.date-picker .input .result {
				width: calc(100% - 21px);
				height: 30px;
				line-height: 30px;
				font-size: 1em;
				padding: 0 10px;
				color: grey;
				box-sizing: border-box;
				font-weight: bold;


				span {
					color: black;
					font-weight: bold;
				}

			}

			.date-picker .input button {
				width: 5px;
				height: 27px;
				background-color: #fff;
				color: white;
				line-height: 27px;
				border: 0;
				font-weight: bold;
				font-size: 16px;
				padding: 0;
				position: relative;
				left: 0px;
				cursor: pointer;
				margin-top: 2px;

				.fa-angle-down {
					color: gray;
				}

			}

			.date-picker .input button:hover {
				background-color: #fff;
			}

			.date-picker .input button:focus {
				outline: none;
			}

			.date-picker .calendar {
				position: relative;
				width: 100%;
				background: #fff;
				border-radius: 3px;
				overflow: hidden;
				margin-top: 15px;
			}

			.date-picker.open {
				max-height: 400px;
			}

			.date-picker .ui-datepicker-inline {
				position: relative;
				width: 100%;
			}

			.date-picker .ui-datepicker-header {
				height: 100%;
				line-height: 50px;
				background: #8392A7;
				color: #fff;
				margin-bottom: 10px;
			}

			.date-picker .ui-datepicker-prev,
			.date-picker .ui-datepicker-next {
				width: 20px;
				height: 20px;
				text-indent: 9999px;
				border: 2px solid #fff;
				border-radius: 100%;
				cursor: pointer;
				overflow: hidden;
				margin-top: 12px;
			}

			.date-picker .ui-datepicker-prev {
				float: left;
				margin-left: 12px;
			}

			.date-picker .ui-datepicker-prev:after {
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				margin: -43px 0px 0px 8px;
			}

			.date-picker .ui-datepicker-next {
				float: right;
				margin-right: 12px;
			}

			.date-picker .ui-datepicker-next:after {
				-webkit-transform: rotate(-135deg);
				transform: rotate(-135deg);
				margin: -43px 0px 0px 6px;
			}

			.date-picker .ui-datepicker-prev:after,
			.date-picker .ui-datepicker-next:after {
				content: "";
				position: absolute;
				display: block;
				width: 4px;
				height: 4px;
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}

			.date-picker .ui-datepicker-prev:hover,
			.date-picker .ui-datepicker-next:hover,
			.date-picker .ui-datepicker-prev:hover:after,
			.date-picker .ui-datepicker-next:hover:after {
				border-color: #68768A;
			}

			.date-picker .ui-datepicker-title {
				text-align: center;

				span {
				}

			}

			.date-picker .ui-datepicker-calendar {
				width: 100%;
				text-align: center;
			}

			.date-picker .ui-datepicker-calendar thead tr th span {
				display: block;
				width: 100%;
				color: #37456f;
				margin-bottom: 5px;
				font-size: 15px;
				font-weight: bold;
			}

			.date-picker .ui-state-default {
				display: block;
				text-decoration: none;
				color: #b5b5b5;
				line-height: 34px;
				font-size: 14px;
			}

			.date-picker .ui-state-default:hover {
				background: #49c5fe;
			}

			.date-picker .ui-state-highlight {
				color: #344269;
			}

			.date-picker .ui-state-active {
				color: #344269;
				background-color: rgba(73, 197, 254, 0.49);
				font-weight: 600;
			}

			.date-picker .ui-datepicker-unselectable .ui-state-default {
				color: #344269;
				border: 2px solid transparent;
			}

			.date-picker.open .input button {
				background: #fff;
			}

		}

		.bycountry,
		.bylanguage {
			width: 25%;
		}
		
		.bycountry,
		.bylanguage {
			.c-dropdown {
				position: relative;
				display: inline-block;
				text-align: left;
				width: 100%;
			}

			.c-dropdown__list {
				margin: 20px 0 0 0;
				list-style: none;
				position: absolute;
				left: 0;
				width: 100%;
				opacity: 0;
				visibility: hidden;
				border-radius: 3px;
				background: #fff;
				-webkit-transition: opacity .25s linear, top .25s linear;
				transition: opacity .25s linear, top .25s linear;
			}

			.c-dropdown.is-open .c-dropdown__list {
				opacity: 1;
				visibility: visible;
				top: 100%;
				position: relative;
			}

			.c-dropdown__item {
				padding: 6px 12px;
				font-size: 14px;
				line-height: 20px;
				cursor: pointer;
				color: Gray;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-transition: background-color .25s linear;
				transition: background-color .25s linear;
			}

			.c-dropdown__item:hover {
				background-color: rgba(73, 197, 254, .49);
			}

			/**
			 * Component: Button
			 * --------------------------------------------------
			 */
			.c-button {
				-webkit-appearance: none;
				margin: 0;
				border: 0;
				overflow: visible;
				font: inherit;
				text-transform: none;
				display: inline-block;
				vertical-align: middle;
				text-decoration: none;
				text-align: center;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			.c-button:hover,
			.c-button:focus {
				outline: none;
				text-decoration: none;
			}

			.c-button:not(:disabled) {
				cursor: pointer;
			}

			/**
			 * Component: Button
			 * Modifier: Dropdown
			 * --------------------------------------------------
			 */
			.c-button--dropdown {
				width: 100%;
				height: 30px;
				background-color: #fff;
				color: gray;
				font-size: 14px;
				line-height: 30px;
				border-radius: 3px;
				-webkit-transition: background-color .2s linear;
				transition: background-color .2s linear;
			}

			.c-button--dropdown:hover {
				background-color: none;
			}

			.c-button--dropdown:after {
				content: "";
				position: absolute;
				top: 14px;
				right: 11px;
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-top-color: black;
			}

		}

		.btnsearch {
			button {
				margin-top: -1px;
				min-width: 120px;
				height: 30px;
				padding: 0px;
				font-size: 0.8em;
				border-radius: 3px;
			}

		}

	}

}