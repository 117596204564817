.dashboard__menubookingcoachresult {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	flex-wrap: wrap;

	div {
		border: 2px solid #606983;
		width: 20%;
		height: 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		cursor: pointer;
		background-color: rgba(56, 70, 112, 0.98);
		transition: all 0.25s ease-in-out;
		font-size: 0.8em;
		font-weight: bold;
		
    	


		img {
			width: 70px;
		}

		span {
			margin-top: 15px;
			font-size: 0.9em;
		}

		i {
			position: absolute;
			right: 46%;
			top: 12%;
		}

		&:hover {
			background-color: rgba(39, 52, 84, 0.36);
		}

	}

}

.dashboard__resultbook {
	margin-top: 20px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	.boxprofilecoach {
		margin: 20px 15px 20px 20px;
		width: 192px;

		.profilecoach {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			background: #fff;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			// margin: 20px 10px 10px 15px;

			img {
				width: 100%;
			}

			&__picture {
				width: 80px;
				height: 80px;
				border-radius: 100%;
				overflow: hidden;
				margin-top: 20px;
			}

			&__name {
				font-weight: bold;
				font-size: 1.1em;
				letter-spacing: 0.5px;
				color: #4aafe6;
				margin-top: 15px;
				width: 100%;
				text-align: center;
			}

			&__rate {
				margin-top: 5px;

				/* Rating Star Widgets Style */
				.rating-stars ul {
					list-style-type: none;
					padding: 0;
					-moz-user-select: none;
					-webkit-user-select: none;
					font-size: 0.5em;
					text-align: center;
				}

				.rating-stars ul > li.star {
					display: inline-block;
				}

				/* Idle State of the stars */
				.rating-stars ul > li.star > i.fa {
					font-size: 2.5em; /* Change the size of the stars */
					color: #ccc; /* Color on idle state */
				}

				/* Hover state of the stars */
				.rating-stars ul > li.star.hover > i.fa {
					color: #FFCC36;
				}

				/* Selected state of the stars */
				.rating-stars ul > li.star.selected > i.fa {
					color: #FF912C;
				}

				.success-box,
				.text-message {
					display: none;
				}

			}

			&__location {
				margin-top: 5px;
				font-weight: bold;
				display: flex;
				justify-content: space-around;
				align-items: baseline;
				color: rgb(128, 128, 128);
				font-size: 0.9em;

				.fa-map-marker {
					color: gray;
					margin-right: 7px;
				}

			}

			&__token {
				margin-top: 5px;
				margin-bottom: 10px;
				font-weight: bold;
				display: flex;
				justify-content: space-around;
				color: rgb(128, 128, 128);
				font-size: 0.9em;
				align-items: baseline;
			}

			.fa-google-wallet {
				color: gray;
				margin-right: 7px;
				margin-left: -20px;
			}

		}

		.profilecoach__timebook {
			.accordion_book {
				padding: 0;
				list-style: none;
				border-top: 1px solid #e5e5e5;
			}

			.accordion-item {
				border-bottom: 1px solid #e5e5e5;
				width: 100%;			
			}

			/* Thumb */
			.accordion-thumb {
				margin: 0;
				padding: 5px;
				cursor: pointer;
				font-weight: normal;
				font-size: 0.8em;
				text-align: center;
				border-bottom: 1px solid #e5e5e5;
				background-color: rgb(239, 239, 239);

				span {
					color: gray;
				}

				.fa-angle-down {
					color: gray;
					position: relative;
					left: 30px;
				}

			}

			/* Panel */
			.accordion-panel {
				margin: 0;
				padding-bottom: .8rem;
				display: none;
				// position: absolute;
				background-color: rgb(239, 239, 239);
				width: 192px;
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
				overflow-y: scroll;
				height: 100px;

				.booking {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					i {
						color: black;
						margin-top: 5px;
						font-size: 0.9em;
						font-weight: bold;
					}

					a {
						color: gray;
						margin-top: 5px;
						font-size: 0.9em;

						&:hover {
							color: #fff;
						}

					}

					&:hover {
						background-color: #4aafe6;
					}

				}

			}

			/* Active */
			.accordion-item.is-active {
			}

		}

	}

}

// ======================= Booking Sumarry ================//


.dashboard__bxsummary {
	margin-top: 20px;
	margin-bottom: 30px;

	&__bookingsummary {
		border: 1px solid #606983;
		width: 73.3%;
		justify-content: center;
		background-color: rgba(56, 70, 112, 0.98);

		h3 {
			font-size: 1.2em;
			font-weight: bold;
			margin-left: 75px;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #606983;
			margin-left: 75px;
			margin-right: 75px;
			height: 55px;
			
			&:nth-child(7) {
				border: none;
			}

			label {
				color: #49C5FE;
				font-size: 0.9em;
			}

			span {
				font-size: 0.8em;
			}

		}

		.bxbutton {
			width: 60%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100px;
			margin: auto;
			border: none;

			button {
				min-width: 90px;
				color: #fff;
				margin-bottom: 30px;
				outline: none;
				border: 0;

				&:nth-child(1) {
					background-color: rgba(73, 197, 254, 0.32);

					&:hover {
						background-color: #49c5fe;
					}

				}

				&:nth-child(2) {
					background-color: rgba(239, 115, 122, 0.46);

					&:hover {
						background-color: #ef737b;
					}

				}

			}

		}

	}

}

.flex-dummy {
	height: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}