.dashboard {
	&__notif {
		margin-top: 10px;
		background-color: #26b2a1;
		color: #fff;
		width: 47%;
		height: 37px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		span {
			font-size: 0.9em;
		}

		i {
			position: absolute;
		    right: 1%;
		    top: 5px;
		    font-size: .7em;
		    cursor: pointer;
		}

	}

	

	&__menutab {
		padding-bottom: 2rem;

		.tabsessions {
			display: flex;
			justify-content: center;
			align-self: center;

			ul {
				display: flex;
				justify-content: space-around;
				color: #fff;
				font-family: $headlineFont;
				font-size: 0.9em;
				letter-spacing: -0.4px;
				width: 100%;
				cursor: pointer;
				height: 32px;

				li {
					background-color: rgba(70, 87, 138, 0.3);
					width: 100%;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #aab0c2;
					border: 2px solid #606983;

					&:nth-child(1) {
						border-right: none;
					}

				}

				li.current {
					background-color: rgba(70, 87, 138, 0.9);
					transition: all 0.7s;
					color: #fff;
				}

			}

		}

		.boxsessions {
			margin-top: 20px;

			&__today {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.todaysessions {
					display: flex;
					flex-direction: column;
					border: 1px solid #606983;
					border-radius: 3px;
					width: 49%;
					height: 140px;
					margin-bottom: 20px;
					background-color: rgba(56, 70, 112, 0.98);

					span {
						font-size: 0.9em;
						margin-left: 10px;

						&:nth-child(1) {
							font-weight: 700;
							margin-top: 10px;
							margin-bottom: 10px;
						}

					}

					.boxinfo {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						margin: 10px;

						.playsession {
							border: 3px solid #606983;
							width: 35px;
							height: 35px;
							line-height: 35px;
							border-radius: 100%;
							text-align: center;
							cursor: pointer;
							margin: 0 5px;

							// svg {
							// 	cursor: pointer;
							// 	position: relative;
							// 	top: 10px;
								
							// }

							// #WebView-NeoLive:hover{
							// 		stroke:"red";
							// 		fill:#fff;
							// 	}

							.fa-play {
								color: #606983;
								font-size: 0.9em;

							}

						}

						.coachinfo {
							border: 3px solid #606983;
							width: 124px;
							border-radius: 21px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							color: #7d86a4;
							cursor: pointer;
							font-weight: 600;

							&:hover {
								background-color: rgba(39, 52, 84, 0.36);
							}

						}

					}

				}

			}
			&__upcoming {
				display: none;
			}

		}

	}

}