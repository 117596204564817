/* circle progress */
	.progress__step {
	    width: 25%;
		max-height: 300px;
		border: 1px solid rgba(250, 250, 250, 0.1);
	    @extend .flex__column--c;
	    justify-content: space-evenly;
	    background-color: #3c4c77;
	    h5 {
	    	font {
	    		font-size: 1.4em;	
	    	}
	    }
	}
	.outter--circle {
		position: relative;
	}
	.step--circle {
		position: relative;
		svg {
		  	position: absolute;  
		  	top: -5px;
		  	left: -5px;
		  	height: 160px;
		  	width: 160px;
		}
	}
	.inner--circle {
	    position: absolute;
	    top: 15px;
    	left: 15px;
	}
	#donut_devider {
	  	left: -15px;
	  	top: -15px;
	  	position: absolute;
	}
	.donut__progress__info {
		position: absolute;
	    top: 35px;
	    left: 37px;
	    text-align: center;
	    line-height: normal;
		.point__progress__info {
			color: $yellow;
			font-size: 2em;
		}
	}
	.progress__info__label {
		color: $yellow;
		font-size: 1em;
		text-align: center;
	}

	.step__progress__info {
		position: absolute;
	    top: 50px;
	    left: 40px;
	    text-align: center;
		.step__info__label {
			color: $yellow;
			font-size: 2em
		}
	}

	.progress__achievement {
		width: 50%;
		display: flex;
		justify-content: space-around;
		padding: 10px;
		text-align: center;
		.achievement__point__info{
			padding: 10px;
			h5 {
				font-size: .8em;
				font-weight: 300;
			}
			h3 {
				font-size: 1em;
			}
		}
	}
	.study__progress__achievement {
		width: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		.achievement__point__info{
			h5 {
				color: $lightBlue;
			}
			h3 {
				color: $lightBlue;
			}
		}
	}
	.coach__progress__achievement {
		width: 60px;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-around;
	    .achievement__point__info{
			padding: 10px;
			h5 {
				color: $yellow;
			}
			h3 {
				color: $yellow;
			}
		}
	}
	.bullet__achievement {
		width: 9px;
		height: 9px;
		margin: 2px 0 0 0;
		border-radius: 10px;
		background-color: #222a42;
	}
/* end circle progress */

/* progress bar */
	.study__dashboard__top {
		width: 100%;
		@extend .flex--spacebetween;
	} 
	.progress__point__top {
	    width: 46%;
	    max-height: 300px;
		border: 1px solid rgba(250, 250, 250, 0.1);
	    background-color: #3c4c77;
	    h5 {
	  		text-align: center;
			margin: 20px 10px;
	  	}
	}
	.study__dashboard__bottom {
		width: 100%;
		margin: 20px 0 0;
		@extend .flex--spacebetween;
		.study__progress__graph {
			width: 24%;
		    max-height: 260px;
			border: 1px solid rgba(250, 250, 250, 0.1);
		    background-color: #3c4c77;
		    h5 {
		  		text-align: left;
	    		margin: 20px 10px;
	    		color: $lightBlue;
		  	}
		}
	}
	.graph__wrap {
	  	margin: 0 auto;
	  	padding: 0 20px 10px 50px;
	  	max-width: 1200px;
	}

	/* Bar Graph Class */
	.bar__graph {
	  	position: relative;
	  	width: 80%;
	  	height: auto;
	  	margin-left: auto;
	  	margin-right: auto;
	  	margin-bottom: 50px;
	}

	.graph {
	  	position: relative;
	  	list-style-type: none;
	  	padding: 0;
	  	margin: 0;
	  	width: calc(96%);
	  	left: 4%;
	}

	.graph__bar__cont {
	  	width: 100%;
	  	border-radius: 2px;
	  	margin-bottom: 20px;
	  	display: block;
	  	label {
	  		position: absolute;
	  		top: 4px;
	  		left: 10px;
	  		color: #fafafa;
	  		font-size: 0.8em;
	  	}
	}

	.graph.b2 .v__bar div:nth-child(4) {
	  	z-index: 999;
	  	border-right: 4px solid #fafafa;
	}

	.graph__bar__each {
	  	background: linear-gradient(-90deg, #5f6b8e, #bcc2d3);
	  	transition: all 1s ease-out;
	  	position: relative;
	  	z-index: 98;
	  	display: block;
	  	height: 25px;
	  	width: 0%;
	  	border-radius: 15px 15px 15px 15px;
	}

	.v__bar {
	  	display: flex;
	  	justify-content: space-around;
	  	margin-top: -183px;
	  	padding-bottom: 10px;
	}
	.v__bar .v__line {
	  	width: 100%;
	  	min-height: 180px;
	  	border-right: 2px dotted rgba(252,244,13,0.5);
	  	z-index: 99;
	}

	.graph__bar__each:hover {
	  	transition: all 0.5s ease;
	  	// background: #3d82e7;
	}
	.graph__bar__each:last-child {
	  	margin-bottom: 0;
	}
	// .graph__bar__each:after {
	//   	position: absolute;
	//   	content: attr(data-value);
	//   	display: none;
	//   	font-size: 12px;
	//   	border-radius: 4px;
	//   	background: rgba(0, 0, 0, 0.5);
	//   	color: #fff;
	//   	line-height: 20px;
	//   	height: 20px;
	//   	padding: 0 10px;
	//   	margin-left: 5px;
	//   	left: 100%;
	//   	top: 0;
	// }
	.graph__bar__each:hover:after {
	  	display: block;
	}

	.graph__legend {
	  	position: absolute;
	  	margin-right: 10px;
	  	font-size: 1em;
	  	top: 3px;
	  	left: -50px;
	  	z-index: 9999;
	}
/* end progress bar */