.dashboard__menu {
	display: flex;
	justify-content: space-between;

	img {
		width: 100%;

	}

	div {
		position: relative;
		border: 2px solid #606983;
		width: 20%;
		height: 140px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		cursor: pointer;
		background-color: rgba(56, 70, 112, 0.98);
		transition: all 0.25s ease-in-out;


		img {
			width: 70px;
		}

		span {
			margin-top: 15px;
			font-size: 0.9em;
		}

		i{
			position: absolute;
		    right: 15%;
		    top: 10%;

		}

		.notactive{
			color:  #606983;
		}

		&:hover {
			background-color: rgba(39, 52, 84, 0.36);
		}


	}

}