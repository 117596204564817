

.titlearco {
  font-size: 2.5em;
  font-family: $title-font;
  color: #49C5FE;
  margin-bottom: 30px;
  margin-top: 15px;
  text-align: center;
}

.accordion {
  width: 49%;
  color: #fff;
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0 auto;
  @extend .no-select;

  & li {
    position: relative;
    margin: 10px 0;
    padding: 12px 0;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: flipdown 0.5s ease both;

    & h2 {
      padding: 0 5px;
    }

    & i {
      position: absolute;
      transform: translate(-6px, 0);
      margin: 16px 0 0;
      top: 5px;
      right: 7px;

      &:before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(-3px,-1px) rotate(45deg);
      }

      &:after {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(2px, -2px) rotate(-45deg);
      }

    }

    & input[type=checkbox] {
      position: absolute;
      // cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;

      &:checked ~ {
        & .accordion__menu {
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          transform: translate(0, 50%);
        }

        & i:before {
          transform: translate(2px, 1px) rotate(45deg);
        }

        & i:after {
          transform: translate(-3px,1px) rotate(-45deg);
        }

      }

    }

    // &:nth-of-type(1) {
    //   animation-delay: 0.5s;
    // }

    // &:nth-of-type(2) {
    //   animation-delay: 0.75s;
    // }

    // &:nth-of-type(3) {
    //   animation-delay: 1s;
    // }

    // &:nth-of-type(4) {
    //   animation-delay: 1.25s;
    //   margin-bottom: 60px;
    // }

  }

  &__menu {
    position: relative;
    max-height: 800px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;
    overflow: hidden;
    opacity: 1;
    transform: translate(0, 0);
    margin: 14px 0 0;
    z-index: 2;
    border: 1px solid transparent;
    transition: all 0.25s ease-in-out;

    &:before {
      content:"• ";
    }
  }

}

.pt_logo {
  margin: 0 0 35px;
  @extend .flex--center;
}

.nowgoal_contents {
  @extend .flex--spacearound;
  width: 90%;
  margin: 0 auto;
}

.nowgoal_content1 {
  & h2 {
    margin: 0 0 20px;
    @extend .font--xlarge;
  }

  & ul {
    padding: 10px 0 0 25px;
  }

  & ul li {
    line-height: 2.5;
    list-style-type: disc;
    @extend .font--medium;
  }

}

.nowgoal_btn {
  @extend .flex__column--c;
  margin-top: 65px;
}

.prev_step {
  cursor: pointer;
  @extend .font--small;
  @extend .margin-b-25;
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }

}