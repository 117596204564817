.dashboard__bxtokenstab {
	padding-bottom: 2rem;

	.tabtokens {
		display: flex;
		justify-content: center;
		align-self: center;
		margin-top: 20px;

		ul {
			display: flex;
			justify-content: space-around;
			color: #fff;
			font-family: $headlineFont;
			font-size: 0.9em;
			letter-spacing: 0.5px;
			width: 100%;
			cursor: pointer;
			height: 32px;
			font-weight: bold;

			li {
				background-color: rgba(70, 87, 138, 0.3);
				width: 100%;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #aab0c2;
				border: 2px solid #606983;

				&:nth-child(1) {
					border-right: none;
				}

			}

			li.current {
				background-color: rgba(70, 87, 138, 0.9);
				transition: all 0.7s;
				color: #fff;
			}

		}

	}

	.bxtabtokens {
		margin-top: 20px;

		.bxrequest {
			&__requesttokens {
				border: 1px solid #606983;
				width: 50%;
				height: 170px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background-color: rgba(56, 70, 112, 0.98);
				border-radius: 3px;

				&__bxbalance {
					width: 85%;
					display: flex;
					justify-content: space-between;
					font-size: 1.2em;

					label {
						color: #49c5fe;
					}

					span {
						font-weight: bold;
					}

				}

				&__buttonreq {
					margin-top: 20px;
					margin-bottom: 20px;

					button {
						min-width: 140px;
					}

				}

			}

		}

		.bxhistory {
			li {
				margin-top: 10px;
				border: 2px solid #606983;
				background-color: rgba(56, 70, 112, .98);
			}

			&__boxnotifstatus,
			&__boxstatus,
			&__boxdebit,
			&__boxcredit,
			&__boxbalance {
				border-bottom: 1px solid #606983;
				display: flex;
			}

			&__boxnotifstatus {
				background-color: rgba(39, 52, 84, .36);
				flex-direction: column;
				height: 80px;
				justify-content: center;
				cursor: pointer;

				i {
					font-size: 0.8em;
					color: rgba(255, 255, 255, 0.71);
				}

			}

			&__boxstatus {
				flex-direction: column;
				height: 130px;
				justify-content: center;

				.daterefund {
					display: flex;
					flex-direction: column;

					i {
						font-size: 0.8em;
						color: rgba(255, 255, 255, 0.71);
					}

				}

				.status {
					margin-top: 30px;
					display: flex;
					justify-content: space-between;
				}

			}

			&__boxdebit,
			&__boxcredit,
			&__boxbalance {
				height: 40px;
				justify-content: space-between;
				align-items: center;
			}

			&__boxnotifstatus,
			&__boxstatus {
				i,
				span {
					margin-left: 30px;
				}

			}

			&__boxstatus,
			&__boxdebit,
			&__boxcredit,
			&__boxbalance {
				label {
					color: #49c5fe;
					margin-left: 30px;
				}

				span {
					font-weight: bold;
					margin-right: 30px;
				}

			}

		}

	}

}