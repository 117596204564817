.btn {
	@extend .text-center;
	appearance: none;
	text-transform: uppercase;
	padding: 1em 1em;
	border-radius: 50px;
	margin: 20px 20px;
	cursor: pointer;
	font-family: $bodyFontThin;

	&__primary {
		@include hoverer(background-color, $secondary, darken($secondary, 10%));
		transition: all $ease-in-quad .2s;
	}

	&__secondary {
		background-color: transparent;
	}

}

//------------Button Neo Settings-------------------//



.neobutton {
	@extend .blue-text-small;
    background: $tertiary;
    border: 1px solid $secondary;
    border-radius: 100px;
    margin: 0 auto;
    padding: .75em 1.5em;
    transition: all ease .5s;
    cursor: pointer;
    margin-top: 15px;
    min-width: 160px;
	@include hoverer(background-color, $tertiary, $secondary);
    @include hoverer(color, $secondary, $white);
    @include bp (max, 390px) {
        padding: .5em 1em;
    }
    &__white {
    	@extend .neobutton;
    	background: none;
    	border: 0;
    	color: #fff;
    }
	

}

@keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 100px 55px;
		opacity: 0.5;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}

}