/* ============================ HEADER ========================================== */
header {
	height: 80px;
	background-color: rgba($primary, .7);
	align-items: center;
	transition: all 0.6s;
	display: flex;
	flex-direction: row;
	margin: auto !important;
}

/* ============================ NAVIGASI & BRAND ========================================== */
.navcenter {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	height: 50px;

	.navcenter__neo {
		width: 300px;
	}

}

.navright {
	position: absolute;
	right: 15%;
	font-family: $headlineFont;
	font-size: $bodySize;
	letter-spacing: -0.5px;
	text-shadow: 0px 1px 10px #fff;

	a:hover {
		color: #fff;
	}

}